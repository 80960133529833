<template>
  <mp-box>
    <!-- Modal Popup Confirmation -->
    <mp-modal
      :is-open="confirmationPopup.isShow"
      :on-close="handleCloseConfirmation"
      :close-on-overlay-click="false"
      :close-on-esc="false"
    >
      <mp-modal-content>
        <mp-modal-header
          >Confirmation of Company Person in Charge (PIC)</mp-modal-header
        >
        <mp-modal-close-button />
        <mp-modal-body>
          <mp-text mb="4">
            {{
              `You can update the PIC data if necessary or directly confirm if ${dataPIC.name} and the associated data are still valid as the Person in Charge (PIC) for Qontak-related business at your company.`
            }}
          </mp-text>
          <mp-table>
            <mp-table-body>
              <mp-table-row>
                <mp-table-cell
                  as="td"
                  scope="row"
                  class="border-bottom-zero"
                  is-fixed
                >
                  PIC Name
                </mp-table-cell>
                <mp-table-cell as="td" scope="row" class="border-bottom-zero">
                  {{ dataPIC.name }}
                </mp-table-cell>
              </mp-table-row>
              <mp-table-row>
                <mp-table-cell
                  as="td"
                  scope="row"
                  class="border-bottom-zero"
                  is-fixed
                >
                  Phone number
                </mp-table-cell>
                <mp-table-cell as="td" scope="row" class="border-bottom-zero">
                  {{ dataPIC.phone_number ? dataPIC.phone_number : '-' }}
                </mp-table-cell>
              </mp-table-row>
              <mp-table-row>
                <mp-table-cell
                  as="td"
                  scope="row"
                  class="border-bottom-zero"
                  is-fixed
                >
                  Email
                </mp-table-cell>
                <mp-table-cell as="td" scope="row" class="border-bottom-zero">
                  {{ dataPIC.email }}
                </mp-table-cell>
              </mp-table-row>
            </mp-table-body>
          </mp-table>
        </mp-modal-body>
        <mp-box
          as="footer"
          display="flex"
          :pt="2"
          :px="4"
          :pb="4"
          justify-content="flex-end"
          data-pixel-component="MpModalFooter"
        >
          <mp-button
            variant="outline"
            margin-right="3"
            @click="handleConfirmPicValidation"
            >Confirm</mp-button
          >
          <mp-button left-icon="edit" @click="handleToUpdateValidation">
            Update data
          </mp-button>
        </mp-box>
      </mp-modal-content>
      <mp-modal-overlay />
    </mp-modal>
    <!-- Modal Update PIC Validation -->
    <mp-modal
      :is-open="updateValidationPopup.isShow"
      :on-close="handleCloseUpdateValidation"
      :close-on-overlay-click="false"
      :close-on-esc="false"
    >
      <mp-modal-content>
        <mp-modal-header>Update Person in Charge (PIC) data</mp-modal-header>
        <mp-modal-close-button />
        <mp-modal-body>
          <ValidationObserver ref="form">
            <form
              novalidate
              @submit.prevent
              @keydown.enter="$event.preventDefault()"
            >
              <mp-form-control
                control-id="username"
                margin-bottom="4"
                is-required
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="PIC name"
                  rules="required"
                >
                  <mp-form-label required>PIC name</mp-form-label>
                  <mp-input v-model="dataPIC.name" />
                  <mp-text v-if="errors && errors.length > 0" color="red.400">
                    {{ errors[0] }}
                  </mp-text>
                </ValidationProvider>
              </mp-form-control>

              <!-- Phone number -->
              <PhoneNumberFieldWithCountryCode
                :phone-number="dataPIC.phone_number"
                @number-phone="getNumberPhone"
              />

              <!-- Email -->
              <mp-form-control control-id="email" margin-bottom="4" is-required>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{
                    required: true,
                    regex:
                      /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!qontak|admin|t\-online|web\.|online\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/,
                  }"
                  name="email"
                >
                  <mp-form-label required>Email</mp-form-label>
                  <mp-input v-model="dataPIC.email" />
                  <mp-text v-if="errors && errors.length > 0" color="red.400">
                    {{ 'Format is incorrect, please recheck your email' }}
                  </mp-text>
                </ValidationProvider>
              </mp-form-control>
            </form>
          </ValidationObserver>
        </mp-modal-body>
        <mp-box
          as="footer"
          display="flex"
          :pt="2"
          :px="4"
          :pb="4"
          justify-content="flex-end"
          data-pixel-component="MpModalFooter"
        >
          <mp-button
            variant="outline"
            margin-right="3"
            @click="handleCloseUpdateValidation"
            >Cancel</mp-button
          >
          <mp-button left-icon="edit" @click="handleSubmitPicValidation">
            Submit
          </mp-button>
        </mp-box>
      </mp-modal-content>
      <mp-modal-overlay />
    </mp-modal>
    <!-- Modal Success -->
    <mp-modal
      :is-open="successPopup.isShow"
      :on-close="handleCloseSuccess"
      :close-on-overlay-click="false"
      :close-on-esc="false"
    >
      <mp-modal-content>
        <mp-modal-body text-align="center">
          <mp-image
            :src="
              require(`~/assets/images/graphics/${
                successPopup.image ? successPopup.image : 'thank-you.png'
              }`)
            "
            :width="288"
            :height="240"
          />
          <mp-text font-size="lg" font-weight="semibold">
            {{ successPopup.title }}
          </mp-text>
          <mp-text color="gray.600">{{ successPopup.desc }}</mp-text>
          <mp-button
            type="submit"
            mt="4"
            default="Contact support"
            @click="handleCloseSuccess"
            >Ok, got it</mp-button
          >
        </mp-modal-body>
      </mp-modal-content>
      <mp-modal-overlay />
    </mp-modal>
  </mp-box>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import PhoneNumberFieldWithCountryCode from '../PhoneNumberField.vue'
import { getStorage, removeItemStorage } from '../../../utils/storage'

export default {
  name: 'ModalPICValidationAccount',
  components: {
    PhoneNumberFieldWithCountryCode,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      confirmationPopup: {
        isShow: false,
      },
      updateValidationPopup: {
        isShow: false,
      },
      successPopup: {
        isShow: false,
        title: '',
        state: 'confirm',
        image: '',
        desc: '',
      },
      popupImageMap: {
        confirm: 'done-check.png',
        update: 'thank-you.png',
      },
      dataPIC: {
        name: '',
        email: '',
        phone: '',
      },
    }
  },
  mounted() {
    this.checkingValidationPIC()
  },
  methods: {
    async checkingValidationPIC() {
      let loginCount
      const CHAT_LOGIN_COUNT = localStorage.getItem('CHAT_LOGIN_COUNT') || '[]'
      const anyData = JSON.parse(CHAT_LOGIN_COUNT).filter((data) => {
        return data.email === this.$auth?.user?.email
      })
      if (anyData.length > 0) {
        loginCount = anyData[0].count
      }

      const data = await this.$store.dispatch('preferences/getPicValidation')
      let phoneNumber
      // Check data if phone number start with '+'
      if (data?.phone_number) {
        phoneNumber = data?.phone_number
        const firstNumber = data.phone_number.charAt(0)
        if (firstNumber === '+') {
          data.phone_number = data.phone_number.substring(1)
          phoneNumber = data?.phone_number
        }
      }
      this.dataPIC = {
        name: data?.name,
        phone_number: phoneNumber,
        email: data?.email,
        created_at: data?.created_at,
        updated_at: data?.updated_at,
      }
      // cookie for trigger PIC Validation modal
      const triggered = Boolean(getStorage('pic_validation')) || false
      // show when data not verified and 3 times after logged in
      if ((!data?.verified && loginCount % 4 === 0) || triggered) {
        this.confirmationPopup.isShow = true
      }
      removeItemStorage('pic_validation', { domain: process.env.HOST_URL })
    },
    getNumberPhone(value) {
      this.dataPIC.phone_number = value
    },
    handleCloseSuccess() {
      this.successPopup.isShow = false
    },
    handleCloseConfirmation() {
      this.confirmationPopup.isShow = false
    },
    handleConfirmPicValidation() {
      const params = {
        name: this.dataPIC.name,
        phone_number: this.dataPIC.phone_number,
        email: this.dataPIC.email,
      }

      this.$store
        .dispatch('preferences/updatePicValidation', params)
        .then(() => {
          this.confirmationPopup.isShow = false
          this.successPopup = {
            state: 'confirm',
            isShow: true,
            image: this.popupImageMap.confirm,
            title: 'Person in Charge (PIC) data confirmed',
            desc: 'For any future updates, you can contact our support team.',
          }
        })
        .catch((err) => {
          const errorMsg = err?.error?.messages?.[0]
          this.$toast({
            variant: 'error',
            title: errorMsg,
            position: 'top',
            duration: 4000,
          })
        })
    },
    handleToUpdateValidation() {
      this.confirmationPopup.isShow = false
      this.updateValidationPopup.isShow = true
    },
    handleCloseUpdateValidation() {
      this.updateValidationPopup.isShow = false
    },
    handleSubmitPicValidation() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          // eslint-disable-next-line no-useless-return
          return
        }
        const params = {
          name: this.dataPIC.name,
          phone_number: this.dataPIC.phone_number,
          email: this.dataPIC.email,
        }

        this.$store
          .dispatch('preferences/updatePicValidation', params)
          .then(() => {
            this.updateValidationPopup = false
            this.successPopup = {
              state: 'update',
              isShow: true,
              image: this.popupImageMap.update,
              title: 'Person in Charge (PIC) data submitted',
              desc: 'Your company Person in Charge (PIC) data is being updated. For any future updates, you can contact our support team.',
            }
          })
          .catch((err) => {
            const errorMsg = err?.error?.messages?.[0]
            this.$toast({
              variant: 'error',
              title: errorMsg,
              position: 'top',
              duration: 4000,
            })
          })
      })
    },
  },
}
</script>
<style>
.border-bottom-zero {
  border-bottom: none;
}
</style>
