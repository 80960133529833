<template>
  <div>
    <b-modal
      id="notification-modal"
      v-model="notification.display"
      :hide-header="true"
      :hide-footer="true"
      size="sm"
      modal-class="modal-position"
      :no-close-on-backdrop="notification.closeable"
      @hide="closeNotification"
      @keyup.enter="handlerMethod"
    >
      <div class="text-center">
        <i
          v-if="notification.type === 'success'"
          class="las la-check-circle qon-text--primary"
          style="font-size: 80px"
        ></i>
        <i
          v-else-if="
            notification.type === 'alert' || notification.type === 'warning'
          "
          class="las la-exclamation-circle qon-text--primary"
          style="font-size: 80px"
        >
        </i>
        <i
          v-else-if="notification.type === 'failed'"
          class="las la-exclamation-circle text-danger"
          style="font-size: 80px"
        ></i>
        <i
          v-else-if="notification.type === 'caution'"
          class="las la-exclamation-triangle text-danger"
          style="font-size: 80px"
        ></i>
        <p
          class="mt-3 mb-3"
          :class="{ 'font-weight-bold fs-16': notification.type === 'caution' }"
        >
          {{ notification.message }}
        </p>
        <p class="mt-3 mb-3 qon-text--muted">
          {{ mapNotifText(notification.item) }}
        </p>
        <div v-if="notification.type !== 'caution'">
          <button class="btn btn-primary" @click="handlerMethod">
            {{ notification.callback.text }}
          </button>
        </div>
        <div v-else>
          <button
            class="btn qon-button-cancel__caution"
            @click="closeNotification"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary font-weight-bold"
            @click="handlerMethod"
          >
            {{ notification.callback.text }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UINotification',
  computed: {
    ...mapState('layouts', ['notification']),
    ...mapState('channels/facebook', ['reconnectData']),
  },
  methods: {
    handlerMethod() {
      this[this.notification.callback.method]()
    },
    closeNotification() {
      this.$store.commit('layouts/UPDATE_NOTIFICATION', {
        display: false,
        type: this.notification.type,
        message: this.notification.message,
        item: this.notification.item,
        closeable: this.notification.closeable,
        callback: {
          text: this.notification.text,
          method: this.notification.method,
        },
      })
    },
    async redirectToSettingsChatTemplates() {
      await this.$router.push('/settings/chat/templates')
      this.closeNotification()
    },
    async redirectToTemplate() {
      await this.$router.push('/whatsapp/templates')
      this.$store.dispatch('template/getTemplates', { limit: 10, offset: 1 })
      this.closeNotification()
    },
    async redirectToCampaign() {
      await this.$router.push('/whatsapp/campaigns')
      this.closeNotification()
    },
    async redirectToContact() {
      await this.$router.push('/whatsapp/contacts')
      this.$store.dispatch('campaign/getContactLists', { limit: 5, offset: 1 })
      this.closeNotification()
    },
    async redirectToContactDetail() {
      await this.$router.push('/whatsapp/contacts/' + this.notification.subItem)
      this.closeNotification()
    },
    async redirectToWhatsappChannel() {
      await this.$router.push('/integrations/whatsapp')
      this.closeNotification()
    },
    async redirectToQiscusChannel() {
      await this.$router.push('/integrations/qiscus')
      this.closeNotification()
    },
    async redirectToQontakChannel() {
      await this.$router.push('/integrations/qontak_crm')
      this.closeNotification()
    },
    async getTemplates() {
      await this.$store.dispatch('template/getTemplateLists', {
        limit: 10,
        offset: 1,
      })
      this.closeNotification()
    },
    async getCampaigns() {
      await this.$store.dispatch('campaign/getCampaigns', {
        limit: 10,
        offset: 1,
      })
      this.closeNotification()
    },
    async getContacts() {
      await this.$store.dispatch('contact/getContacts', {
        limit: 5,
        offset: 1,
      })
      this.closeNotification()
    },
    async redirectToOrganization() {
      await this.$store.dispatch('organization/getOrganizations', {
        limit: 10,
        offset: 1,
        id: this.$auth.user.organization_id,
      })
      this.closeNotification()
    },
    async refreshUsers() {
      await this.$store.dispatch('users/getUsers', {
        limit: 10,
        offset: 1,
      })
      this.closeNotification()
    },
    async refreshUsersAdmin() {
      await this.$store.dispatch('users/getUsersAdmin', {
        limit: 10,
        offset: 1,
      })
      this.closeNotification()
    },
    async refreshOrganizations() {
      await this.$store.dispatch('organization/getOrganizations', {
        limit: 10,
        offset: 1,
        order_by: 'created_at',
        order_direction: 'desc',
      })
      this.closeNotification()
    },
    async refreshQiscusIntegration() {
      await this.$router.push('/integrations/qiscus')
      this.$store.dispatch('channels/qiscus/getConnection', {
        target_channel: 'qiscus',
      })
      this.closeNotification()
    },
    async refreshFacebookIntegration() {
      await this.$router.push('/integrations/facebook_messenger')
      this.$store.dispatch('channels/facebook/getConnection', {})
      this.closeNotification()
    },
    async refreshQontakIntegration() {
      await this.$router.push('/integrations/qontak_crm')
      this.$store.dispatch('channels/qontak_crm/getConnection', {
        target_channel: 'qontak',
      })
      this.closeNotification()
    },
    async refreshInstagramIntegration() {
      await this.$router.push('/integrations/instagram')
      this.closeNotification()
    },
    async refreshTelegramIntegration() {
      await this.$router.push('/integrations/telegram')
      this.$store.dispatch('channels/telegram/getConnection', {})
      this.closeNotification()
    },
    async refreshEmailIntegration() {
      await this.$router.push('/integrations/email')
      this.$store.dispatch('channels/email/getEmailConnection', {
        parameter: { offset: 1, limit: 10, target_channel: 'email' },
      })
      this.closeNotification()
    },
    async refreshLineIntegration() {
      await this.$router.push('/integrations/line_messenger')
      this.$store.dispatch('channels/line/getConnection', {})
      this.closeNotification()
    },
    async refreshTwitterIntegration() {
      await this.$router.push('/integrations/twitter')
      this.$store.dispatch('channels/twitter/getConnection', {})
      this.closeNotification()
    },
    async redirectToInbox() {
      await this.$router.push('/inbox')
      await this.$nuxt.$emit(
        'onHandleStatusRoomChange',
        this.$route.query.status
      )
      await this.$store.commit('conversation/UPDATE_QUERY_ROOM', null)
      this.closeNotification()
    },
    async redirectToHsmTemplate() {
      await this.$router.push('/whatsapp/hsm')
      await this.$store.dispatch('template/getTemplates', {
        limit: 10,
        hsm_chat: true,
        offset: 1,
      })
      this.closeNotification()
    },
    async mergeChannelContact() {
      await this.closeNotification()
    },
    async removeChannelContact() {
      await this.closeNotification()
    },
    async redirectToLivechatChannel() {
      await this.closeNotification()
      this.$store.dispatch('channels/livechatcom/getConnection', {
        target_channel: 'livechat_dot_com',
      })
    },
    async redirectToFbReconnect() {
      await this.closeNotification()
      this.$router.push(
        `/integrations/facebook_messenger/reconnect/${this.reconnectData.settings.page_id}`
      )
    },
    mapNotifText(text) {
      if (typeof text === 'string') {
        const lowercaseText = text.toLowerCase()
        const forbiddenCriteria = ['oauth scope', 'disallowed', 'forbidden']

        if (
          forbiddenCriteria.some((criteria) => lowercaseText.includes(criteria))
        )
          return "You don't have permission for this action"
        else return text
      } else {
        return JSON.stringify(text)
      }
    },
  },
}
</script>
