<template>
  <mp-modal
    :is-open="isOpenModalFreeze"
    :on-close="
      () => {
        $store.commit('package_usage/billings/UPDATE_MODAL_FREEZE', false)
      }
    "
  >
    <mp-modal-content>
      <mp-modal-close-button />
      <mp-modal-body text-align="center">
        <mp-image
          :src="require('~/assets/images/graphics/payment-issue.png')"
          :width="288"
          :height="240"
        />
        <mp-text font-size="lg" font-weight="semibold">
          Your access is restricted
        </mp-text>
        <mp-text color="gray.600"
          >To continue accessing, please contact our support team to consult
          further.</mp-text
        >
        <mp-button mt="4" default="Contact support" @click="openCustomerService"
          >Contact support</mp-button
        >
      </mp-modal-body>
    </mp-modal-content>
    <mp-modal-overlay />
  </mp-modal>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalFreezeStatusAccount',
  data() {
    return {
      isShow: false,
    }
  },
  computed: {
    ...mapState('package_usage/billings', ['isOpenModalFreeze']),
  },
  methods: {
    openCustomerService() {
      window.open('https://app.qontak.com/contact-support')
    },
  },
}
</script>
